/**
 * /!\ Please note /!\
 * THIS FILE IS AUTOMATICALLY GENERATED DO NOT EDIT
 */

$icons-font-family: "spoticons.af23c462e";
$icons: (
    ic-activity: "\E001",
    ic-airplay: "\E002",
    ic-alert-circle-fill: "\E003",
    ic-alert-circle: "\E004",
    ic-alert-octagon: "\E005",
    ic-alert-triangle: "\E006",
    ic-align-center: "\E007",
    ic-align-justify: "\E008",
    ic-align-left: "\E009",
    ic-align-right: "\E00A",
    ic-anchor: "\E00B",
    ic-aperture: "\E00C",
    ic-arrow-down-arrow-up: "\E00D",
    ic-arrow-down-left: "\E00E",
    ic-arrow-down-right: "\E00F",
    ic-arrow-down: "\E010",
    ic-arrow-left: "\E011",
    ic-arrow-right: "\E012",
    ic-arrow-up-left: "\E013",
    ic-arrow-up-right: "\E014",
    ic-arrow-up: "\E015",
    ic-at-sign: "\E016",
    ic-award: "\E017",
    ic-backward: "\E018",
    ic-badge: "\E019",
    ic-bar-chart-2: "\E01A",
    ic-bar-chart: "\E01B",
    ic-battery-charging: "\E01C",
    ic-battery: "\E01D",
    ic-bell-off: "\E01E",
    ic-bell: "\E01F",
    ic-bluetooth: "\E020",
    ic-book: "\E021",
    ic-bookmark: "\E022",
    ic-box: "\E023",
    ic-briefcase: "\E024",
    ic-building: "\E025",
    ic-burger: "\E026",
    ic-calendar-agenda: "\E027",
    ic-camera-off: "\E028",
    ic-camera: "\E029",
    ic-captions-on: "\E02A",
    ic-captions: "\E02B",
    ic-cast: "\E02C",
    ic-chat: "\E02D",
    ic-check-circle: "\E02E",
    ic-check-fill: "\E02F",
    ic-check-square: "\E030",
    ic-check: "\E031",
    ic-chevron-down: "\E032",
    ic-chevron-left: "\E033",
    ic-chevron-right: "\E034",
    ic-chevron-up: "\E035",
    ic-chevrons-down: "\E036",
    ic-chevrons-left: "\E037",
    ic-chevrons-right: "\E038",
    ic-chevrons-up: "\E039",
    ic-chrome: "\E03A",
    ic-circle: "\E03B",
    ic-clipboard: "\E03C",
    ic-clock: "\E03D",
    ic-cloud-drizzle: "\E03E",
    ic-cloud-lightning: "\E03F",
    ic-cloud-off: "\E040",
    ic-cloud-rain: "\E041",
    ic-cloud-snow: "\E042",
    ic-cloud: "\E043",
    ic-command: "\E044",
    ic-compass: "\E045",
    ic-copy: "\E046",
    ic-corner-down-left: "\E047",
    ic-corner-down-right: "\E048",
    ic-corner-left-down: "\E049",
    ic-corner-left-up: "\E04A",
    ic-corner-right-down: "\E04B",
    ic-corner-right-up: "\E04C",
    ic-corner-up-left: "\E04D",
    ic-corner-up-right: "\E04E",
    ic-cpu: "\E04F",
    ic-credit-card: "\E050",
    ic-crosshair: "\E051",
    ic-dashboard: "\E052",
    ic-database: "\E053",
    ic-delete: "\E054",
    ic-disc: "\E055",
    ic-door: "\E056",
    ic-download-cloud: "\E057",
    ic-download: "\E058",
    ic-droplet: "\E059",
    ic-earpods-bluetooth: "\E05A",
    ic-earth: "\E05B",
    ic-edit-2: "\E05C",
    ic-edit-3: "\E05D",
    ic-edit: "\E05E",
    ic-email-template-copy: "\E05F",
    ic-email-template-paste: "\E060",
    ic-external-link: "\E061",
    ic-external: "\E062",
    ic-eye-2: "\E063",
    ic-eye-off: "\E064",
    ic-eye: "\E065",
    ic-facebook: "\E066",
    ic-fast-forward: "\E067",
    ic-feather: "\E068",
    ic-feed: "\E069",
    ic-file-minus: "\E06A",
    ic-file-plus: "\E06B",
    ic-file-text: "\E06C",
    ic-file: "\E06D",
    ic-film: "\E06E",
    ic-filter: "\E06F",
    ic-flag: "\E070",
    ic-flash: "\E071",
    ic-folder: "\E072",
    ic-fullscreen-out: "\E073",
    ic-fulscreen-in: "\E074",
    ic-gauge: "\E075",
    ic-gear: "\E076",
    ic-github: "\E077",
    ic-globe: "\E078",
    ic-grid: "\E079",
    ic-hang-up: "\E07A",
    ic-hash: "\E07B",
    ic-headphones: "\E07C",
    ic-heart: "\E07D",
    ic-help-chat: "\E07E",
    ic-help: "\E07F",
    ic-home: "\E080",
    ic-image: "\E081",
    ic-in-person: "\E082",
    ic-inbox: "\E083",
    ic-info-fill: "\E084",
    ic-info: "\E085",
    ic-instagram: "\E086",
    ic-internal: "\E087",
    ic-interprefy: "\E088",
    ic-key: "\E089",
    ic-layers: "\E08A",
    ic-layout: "\E08B",
    ic-lead: "\E08C",
    ic-life-buoy: "\E08D",
    ic-link-2: "\E08E",
    ic-link: "\E08F",
    ic-list: "\E090",
    ic-loader: "\E091",
    ic-lock: "\E092",
    ic-log-in: "\E093",
    ic-log-out: "\E094",
    ic-lower-third: "\E095",
    ic-magic-stars-outline: "\E096",
    ic-magic-stars: "\E097",
    ic-magnet: "\E098",
    ic-mail: "\E099",
    ic-map-pin: "\E09A",
    ic-map: "\E09B",
    ic-maximize-2: "\E09C",
    ic-maximize: "\E09D",
    ic-media: "\E09E",
    ic-menu: "\E09F",
    ic-message-circle: "\E0A0",
    ic-message-square: "\E0A1",
    ic-mic-off: "\E0A2",
    ic-mic-outline: "\E0A3",
    ic-mic: "\E0A4",
    ic-minimize-2: "\E0A5",
    ic-minimize: "\E0A6",
    ic-minus-circle: "\E0A7",
    ic-minus-square: "\E0A8",
    ic-minus: "\E0A9",
    ic-mixed: "\E0AA",
    ic-monitor: "\E0AB",
    ic-moon: "\E0AC",
    ic-more-horizontal: "\E0AD",
    ic-more-vertical: "\E0AE",
    ic-move: "\E0AF",
    ic-music: "\E0B0",
    ic-navigation-2: "\E0B1",
    ic-navigation: "\E0B2",
    ic-octagon: "\E0B3",
    ic-open: "\E0B4",
    ic-package: "\E0B5",
    ic-palette: "\E0B6",
    ic-pause-circle: "\E0B7",
    ic-pause: "\E0B8",
    ic-people: "\E0B9",
    ic-percent: "\E0BA",
    ic-phone-call: "\E0BB",
    ic-phone-forwarded: "\E0BC",
    ic-phone-incoming: "\E0BD",
    ic-phone-missed: "\E0BE",
    ic-phone-off: "\E0BF",
    ic-phone-outgoing: "\E0C0",
    ic-phone: "\E0C1",
    ic-pie-chart-2: "\E0C2",
    ic-pie-chart: "\E0C3",
    ic-pin: "\E0C4",
    ic-play-circle: "\E0C5",
    ic-play: "\E0C6",
    ic-plus-circle: "\E0C7",
    ic-plus-square: "\E0C8",
    ic-plus: "\E0C9",
    ic-pocket: "\E0CA",
    ic-power: "\E0CB",
    ic-presentations: "\E0CC",
    ic-printer: "\E0CD",
    ic-qna: "\E0CE",
    ic-qr-code: "\E0CF",
    ic-question: "\E0D0",
    ic-radio: "\E0D1",
    ic-ranking: "\E0D2",
    ic-refresh-ccw: "\E0D3",
    ic-refresh-cw: "\E0D4",
    ic-remote: "\E0D5",
    ic-repeat: "\E0D6",
    ic-report: "\E0D7",
    ic-restore: "\E0D8",
    ic-rewind: "\E0D9",
    ic-rotate-ccw: "\E0DA",
    ic-rotate-cw: "\E0DB",
    ic-save: "\E0DC",
    ic-scissors: "\E0DD",
    ic-screen-share: "\E0DE",
    ic-screen: "\E0DF",
    ic-search: "\E0E0",
    ic-server: "\E0E1",
    ic-session: "\E0E2",
    ic-settings: "\E0E3",
    ic-share-2: "\E0E4",
    ic-share: "\E0E5",
    ic-shield: "\E0E6",
    ic-shop: "\E0E7",
    ic-shuffle: "\E0E8",
    ic-sidebar: "\E0E9",
    ic-skip-back: "\E0EA",
    ic-skip-forward: "\E0EB",
    ic-slack: "\E0EC",
    ic-slash: "\E0ED",
    ic-smartphone: "\E0EE",
    ic-speaker: "\E0EF",
    ic-square: "\E0F0",
    ic-star-outlined: "\E0F1",
    ic-star: "\E0F2",
    ic-stop-circle: "\E0F3",
    ic-store: "\E0F4",
    ic-sun: "\E0F5",
    ic-sunrise: "\E0F6",
    ic-sunset: "\E0F7",
    ic-tablet: "\E0F8",
    ic-tag: "\E0F9",
    ic-target: "\E0FA",
    ic-thermometer: "\E0FB",
    ic-thumbs-down: "\E0FC",
    ic-thumbs-up-fill: "\E0FD",
    ic-thumbs-up: "\E0FE",
    ic-thunderbolt-cable: "\E0FF",
    ic-time: "\E100",
    ic-toggle-left: "\E101",
    ic-toggle-right: "\E102",
    ic-tools: "\E103",
    ic-trash-2: "\E104",
    ic-trash: "\E105",
    ic-trending-down: "\E106",
    ic-trending-up: "\E107",
    ic-triangle: "\E108",
    ic-twitter: "\E109",
    ic-type: "\E10A",
    ic-umbrella: "\E10B",
    ic-unlink: "\E10C",
    ic-unlock: "\E10D",
    ic-upload-cloud: "\E10E",
    ic-upload: "\E10F",
    ic-user-activity: "\E110",
    ic-user-check: "\E111",
    ic-user-minus: "\E112",
    ic-user-outline: "\E113",
    ic-user-plus: "\E114",
    ic-user-x: "\E115",
    ic-user: "\E116",
    ic-users: "\E117",
    ic-video-off: "\E118",
    ic-video-player: "\E119",
    ic-video: "\E11A",
    ic-voicemail: "\E11B",
    ic-volume-1: "\E11C",
    ic-volume-2: "\E11D",
    ic-volume-low: "\E11E",
    ic-volume-max: "\E11F",
    ic-volume-mid: "\E120",
    ic-volume-off: "\E121",
    ic-volume-x: "\E122",
    ic-volume: "\E123",
    ic-warning-fill: "\E124",
    ic-warning-square-fill: "\E125",
    ic-watch: "\E126",
    ic-webcam-off: "\E127",
    ic-webcam: "\E128",
    ic-wifi: "\E129",
    ic-wind: "\E12A",
    ic-x-circle: "\E12B",
    ic-x-square: "\E12C",
    ic-x: "\E12D",
    ic-zap: "\E12E",
    ic-zoom-in: "\E12F",
    ic-zoom-out: "\E130",
);

/**
 * When included, prepares the element to accept font-icons.
 */
 @mixin iconize {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'spoticons.af23c462e' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: none;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/**
 * Sets the requested icon to the content.
 * NOTE: the element where this mixin is used needs to be a valid icon
 * container (font family and all other rules set in `icons.scss`).
 *
 * @param {string} $icon the name of the desired icon.
 * @param {boolean} $self whether the content should be set for self or in the ::before
 */
@mixin icon($icon, $self: true) {
    @if ($self) {
        &::before {
            content: map-get($icons, $icon);
        }
    } @else {
        content: map-get($icons, $icon);
    }
}

/**
 * Transforms the element where it is included into an icon container
 * and draws the specified icon.
 *
 * @param {string} $icon the name of the desired icon.
 * @param {boolean} $self whether the content should be set for self or in the ::before
 */
@mixin iconify($icon, $self: true) {
    @extend %icon;
    @include icon($icon, $self);
}

%icon {
    @include iconize;
}